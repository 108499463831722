.contentHolder {
  height: calc(100% - 124px);
  padding: 80px 40px;
  display: flex;
  overflow: auto;
}

.bannerContainer {
  position: relative;
  width: 468px;
  margin: auto;
  background-color: var(--writer-blue-1);
  padding: 81px 77px 48px;
}

.heading,
.bodyText {
  margin-bottom: 10px;
  text-align: center;
}

.body {
  margin-bottom: 30px;
  text-align: center;
}

.containerHeroLogo {
  width: 150px;
  margin: auto;
}

.sadIcon {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -20px;
}
