*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--color-support-lightest);
  border-radius: 15px;
  border: 5px solid var(--white);
}

* {
  -webkit-font-smoothing: antialiased;
}

.flexVerticalCenter {
  display: flex;
  align-items: center;
}

.flexHorizontalCenter {
  display: flex;
  justify-content: center;
  width: 100%;
}

.flexContainerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hyperLink {
  text-decoration: none;
  color: var(--writer-blue-5);
}

.hyperLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* override default helpscout container styles */
#beacon-container {
  z-index: 9999 !important;
  position: fixed !important;
}

/* ANIMATIONS */
/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes fadeInFromNone {
  0% {
    opacity: 0.4;
  }

  1% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
