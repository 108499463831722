$athens-gray: #eff0f2;
$black: #222628;
$blue: #2f80ed;
$blue-desaturated-dark: #5a677c;
$blue-dim: #e5f4ff;
$blue-dull: #68a1ef;
$blue-primary: #5551ff;
$embed-back: #F3F5FF;
$embed-border: #bfcbff;
$gray-background: #f5f5f9;
$gray-border: #e4e7ed;
$gray-shuttle: #5a677c;
$gray-fonts: #828282;
$green-light: #abf1e2;
$green-back: rgb(169, 249, 225);
$green-border: #a9f9e1;
$green-text: rgb(4, 87, 88);
$orange: #ff643c;
$pink: rgb(238, 70, 211);
$select: #eceffe;
$white: #ffffff;
