@import "../../assets/styles/mixins.scss";

.modal {
  @include flex(center, center);

  h4 {
    margin: 0 30px 32px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    color: #000;
  }

  .modal__header {
    @include flex(flex-end, flex-start);
    height: 30px;
    width: 100%;

    button {
      @include flex(center, center);
      padding: 0;
      width: 20px;
      height: 20px;
      border: none;
      background: none;
      cursor: pointer;

      img {
        width: 11px;
        height: 11px;
      }
    }
  }
}
