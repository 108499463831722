/* Description section */
.description {
  font-size: 11px;
  line-height: 20px;
  white-space: pre-line;
  width: 219px;
  max-height: 200px;
  overflow-y: scroll;
  color: var(--black);
  padding-right: 16px;
  margin-top: 6px;
}

.description b { font-weight: 600 }
.description i { font-style: italic }
.description > span {
  word-break: break-word;
}

.examplesContainer {
  display: flex;
  margin-top: 10px
}

.examplesIcon {
  margin-right: 10px;
  padding-top: 2px;
}

.examplesIcon svg { font-size: 16px }

.scrollbar::-webkit-scrollbar {
  width: 15px;
}

.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--color-support-lightest);
  border-radius: 15px;
  border: 5px solid var(--white);
}
