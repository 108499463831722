@import "../../assets/styles/mixins.scss";

.dialog {
  height: 100vh;
  background-color: $white;
}

.dialog__login {
  background-color: $gray-background;
}

.dialog__settings {
  @include flex(center, center);
  position: absolute;
  left: 11px;
  bottom: 6px;
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 100%;
  background-color: $gray-border;
  cursor: pointer;
  flex-shrink: 0;
}
