@import "../../assets/styles/mixins.scss";

.decoration__card {
  position: absolute;
  padding: 7px 12px 0;
  border-radius: 8px;
  background-color: $green-back;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: $green-back;
  border: 2px solid #045758;
  opacity: 0.3;
  border-radius: 15px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-corner {
  background-color: $green-back;
}
