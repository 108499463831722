.wrapper__background {
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 100vw;
  height: 100vh;
  background: none;
}

.wrapper__modal {
  position: absolute;
  left: 11px;
  bottom: 55px;
  border-radius: 8px;
  background-color: var(--classic-white);
  box-shadow: 0 3px 19px rgba(191, 203, 255, 0.37795);

  .modal__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 84px;
    min-width: 339px;

    svg {
      margin: 19px 16px 19px 26px;
    }

    img {
      width: 40%;
      border-top-right-radius: 8px;
    }
  }

  .modal__selectorsLoading {
    margin: 89px 0;
    text-align: center;
  }

  .modal__main {
    padding: 0 26px;

    .teamOptionsList {
      transition: opacity 200ms ease-in-out;
    }

    .teamOptionsListHidden {
      opacity: 0;
      pointer-events: none;
    }

    .languageOptionsList {
      transition: opacity 200ms ease-in-out;
      margin-bottom: 46px;
    }

    .languageOptionsListHidden {
      opacity: 0;
      pointer-events: none;
    }

    .languageLabel {
      letter-spacing: 1.3px;
    }

    .languageSelectTrigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--writer-graynu-3);
      border-radius: 4px;
      padding: 0 15px;
      font-size: var(--font-size-m);
      font-weight: 400;
      line-height: 28px;
      height: 40px;
    }

    .languageSelectTrigger:hover {
      border: 1px solid var(--writer-blue-3);
      box-shadow: 0 0 0 3px var(--input-shadow);
    }

    .languageSelectTrigger:hover .selectTriggerIcon {
      background: var(--classic-grey-1);
    }

    .languageDropdown {
      margin-top: 4px;
      margin-bottom: 23px;
      width: 100%;
    }

    .dropdownTrigger[data-open='true'] .languageSelectTrigger {
      border: 1px solid var(--writer-blue-3);
      box-shadow: 0 0 0 3px var(--input-shadow);
    }

    .dropdownTrigger[data-open='true'] .languageSelectTriggerIcon {
      background: var(--writer-graynu-2);
    }

    .selectTriggerIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      height: 24px;
      width: 24px;
      margin-right: 5px;
    }

    :global(.flag) {
      background-repeat: no-repeat;
      background-size: 14.5px 13.5px !important;
      background-image: url('../../assets/icons/flags/US.png');
      padding: 5px 5px 5px 27px;
      background-position: 4px center;
    }

    .triggerFlag {
      background-repeat: no-repeat;
      background-size: 17.5px 16.5px !important;
      background-image: url('../../assets/icons/flags/US.png');
      padding: 5px 5px 5px 44px;
      background-position: 15px center;
    }

    :global(.unitedStatesFlag) {
      background-image: url('../../assets/icons/flags/US.png');
    }

    :global(.australiaFlag) {
      background-image: url('../../assets/icons/flags/AU.png');
    }

    :global(.canadaFlag) {
      background-image: url('../../assets/icons/flags/CA.png');
    }

    :global(.unitedKingdomFlag) {
      background-image: url('../../assets/icons/flags/GB.png');
    }
  }

  .modal__bottom {
    margin-top: 28px;
    padding: 12px 26px 17px;
    border-top: 1px solid var(--classic-grey-1);

    .signOutBtn {
      border: 1px solid var(--writer-graynu-2);
      width: 100%;
      transition: background-color 0.2s ease-in-out;
      user-select: none;
      font-size: var(--font-size-m);
    }

    .signOutBtn:hover {
      color: var(--classic-black);
      background-color: var(--writer-graynu-2);
    }
  }
}
