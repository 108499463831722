.main {
  ul[class],
  ol[class] {
    padding: 0;
    margin: 0 0 1.25rem 1.25rem;
  }

  ul[class] {
    list-style-type: disc;
  }

  ol[class] {
    list-style-type: decimal;
  }

  code * {
    font-family: monospace;
  }

  .main__control {
    display: flex;
    margin: 10px 0 20px 0;
    width: 100%;

    & > button img {
      margin-right: 5px;
      width: 15px;
    }

    .control__switch {
      margin: 0 30px;
      height: 40px;
      color: #036fe3;

      & > label {
        font-size: 13px;
      }
    }
  }

  .main__content {
    width: 100%;
  }
}
