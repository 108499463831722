.mainContainer {
  width: 100%;
  max-width: var(--inline-sug-card-max-width);
  min-width: var(--inline-sug-card-min-width);
  border-radius: 8px;
  box-shadow: 0 5.4px 32.5px 0 #e5ebf5;
  background-color: var(--white);
  padding: 11px 17px 13px 17px;
  color: var(--black);
  overflow: visible;
  box-sizing: border-box;
}

.category {
  font-size: 10px;
  letter-spacing: 1.3px;
  font-weight: 600;
  color: var(--classic-grey-3);
  text-transform: uppercase;
  margin-top: 12px;
  margin-bottom: 9px;
}

.description {
  width: calc(100% + 17px)!important;
}

.subHighlight {
  font-weight: 400;
  color: var(--classic-blue-4);
  font-size: 13px;
  margin-left: 5px;
}
