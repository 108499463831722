@import "../../assets/styles/mixins.scss";

.control__buttons {
  button {
    width: 143px;
    height: 36px;
    border: 1px solid $gray-border;
    border-radius: 79px;
    background-color: $white;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
  }

  .buttons__save {
    margin-left: 11px;
    width: 135px;
    background-color: $blue-primary;
    font-weight: 500;
    color: $white;
  }
}
