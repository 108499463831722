@import './styles/index.css';

html {
  overflow: hidden;
}

html, body, div {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Poppins", sans-serif !important;
  font-size: 100%;
  vertical-align: baseline;
  font-style: normal;
}

.ql-writer-snippethighlight {
  color: inherit;
  font-weight: inherit;
  cursor: pointer;
  border-bottom: 2px solid gray;
  background: inherit;
}

.QL_TEXTHIGHLIGHT_CLASS_PREFIX[data-category='dictionary'] {
  background: #e5f4ff;
  border-bottom-color: transparent !important;
}
