.lightningWarnIcon svg path {
  stroke: var(--writer-orange);
  fill: transparent;
}

.containerToolButtonContainer {
  position: absolute;
  top: 0;
  right: 20px;
}

.containerToolButton {
  font-weight: var(--font-default-weight);
  font-size: var(--font-size-s) !important;
}

.toolButtonSquaredLoading i {
  z-index: 2;
}

.toolButtonSquaredLoading span {
  z-index: 2;
}

.toolButtonSquaredLoadingMauve {
  overflow: hidden;
  background: #c586ff;
}

.toolButtonSquaredLoadingMauve:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 200px;
  background: radial-gradient(50% 50% at 50% 50%, #fb76e5 0%, rgba(251, 118, 229, 0) 100%);
  animation: floating-gradient-centered 2s infinite;
}

@keyframes floating-gradient-centered {
  0% {
    top: -100%;
    left: -80%;
  }

  100% {
    top: -50%;
    left: 80%;
  }
}

:global(.MuiTooltip-tooltip) {
  &.tooltipSmall {
    width: 100px;

    & p {
      font-size: 12px;
    }
  }

  &.tooltipMedium {
    width: 135px;

    & p {
      font-size: 12px;
    }
  }

  &.tooltipLarge {
    width: 175px;

    & p {
      font-size: 12px;
    }
  }

  .autoWriteTooltip {
    margin: 3px 0;
  }

  .contactLink a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }

  .betaTag {
    width: 41px;
    margin: 0 auto;
  }
}
