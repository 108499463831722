@import "../../assets/styles/mixins.scss";

.dialog__header {
  @include flex(space-between, center);
  padding: 0 19px;
  height: 62px;
  border-bottom: 1px solid $gray-border;
  background-color: $gray-background;

  h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    color: #000;
  }

  button {
    @include flex(center, center);
    padding: 0;
    width: 20px;
    height: 20px;
    border: none;
    background: none;
    cursor: pointer;

    img {
      width: 11px;
      height: 11px;
    }
  }
}
