.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 62px);
  width: 100%;
  background-color: #F5F5F9;
  overflow: auto;

  .wrapper__sign {
    width: 326px;
    margin: 58px 0;
    padding: 9px 16px 6px;
    background: #FFFFFF;
    box-shadow: 0 3px 40px rgba(172, 185, 220, 0.4);
    border-radius: 8px;

    .loginForm {
      padding: 18px 10px 10px;
    }

    .loginForm > .logo {
      margin-left: -5px;

      rect {
        fill: transparent;
      }
    }

    .loginForm > img {
      user-select: none;
    }

    .loginForm > h2 {
      width: 170px;
      margin: 9px 0 21px;
      font-weight: 600;
      line-height: 29px;
      color: #000;
    }

    .loginForm h2::selection, .loginForm p::selection, .loginForm a::selection {
      background-color: #000;
      color: #FFF;
    }

    .loginForm > .loginCover {
      margin: auto;
      height: 153px;
      width: 198px;
    }

    .bottomControls {
      margin-top: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .bottomControls > p {
      color: #4F4F4F;
      font-size: 13px;
      font-weight: 500;
    }

    .bottomControls > p > a {
      color: #3C64FA;
    }

    .signInButton {
      user-select: none;
      font-size: 15px;
    }
  }
}
