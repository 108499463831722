.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: var(--classic-grey-1);
}

.pleaseWaitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 120px;
  color: var(--black);
  justify-content: space-between;
  padding: 20px 40px;
  border-radius: 8px;
  user-select: none;
}

.logo svg {
  width: 90px;
  height: 90px;
  border: 15px solid var(--white);
  border-radius: 50%;
  box-shadow: var(--popup-box-shadow);
  box-sizing: content-box;
  animation: box-shadow-animation 1.4s infinite ease-in-out;
}
.dotLoader {
  margin-top: 20px;
}
