.sidebar__wrapper {
  transition: all 0.5s ease-in-out;
  position: absolute;
  right: 0;
  z-index: 1;
  height: 100%;
  border-left: 1px solid #e4e7ed;
  overflow: auto;
  width: 446px;

  :global(._scoreTooltip_1knqz_10) {
    top: auto;
    bottom: 20px;
  }
}

:global(.MuiPopper-root) {
  @media only screen and (max-height: 640px) {
    :global(.FleschKincaidScoreTooltip-module__wh9hy6v) {
      height: calc(100vh - 236px);
      overflow-y: auto;
    }
  }

  @media only screen and (min-width: 446px) and (max-height: 644px) {
    :global(.FleschKincaidScoreTooltip-module__wh9hy6v) {
      height: calc(100vh - 236px);
      overflow-y: auto;
    }
  }
}

.sidebar_loading {
  width: 446px;
  height: auto;
  margin: auto;
  text-align: center;
}
