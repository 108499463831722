@import "../../assets/styles/mixins.scss";

:global {
  .qlbt-col-tool {
    display: none !important;
  }

  table.quill-better-table {
    width: 100% !important;
  }

  table.quill-better-table td:not([data-cell-bg]) {
    vertical-align: top;
  }
}

.wrapper {
  height: 100%;

  .wrapper__head {
    @include flex(space-between, center);
    padding: 0 19px;
    height: 62px;
    border-bottom: 1px solid $gray-border;

    h3 {
      margin: 0;
      font-size: 22px;
      font-weight: 600;
    }

    button {
      @include flex(center, center);
      padding: 0;
      width: 20px;
      height: 20px;
      border: none;
      background: none;
      cursor: pointer;

      img {
        width: 11px;
        height: 11px;
      }
    }
  }

  .wrapper__main {
    @include flex(flex-start, flex-start);
    position: fixed;
    width: 100%;
    height: calc(100% - 62px);

    .main__content {
      position: relative;
      @include flex(space-between, center, column);
      margin-top: 10px;
      width: calc(100% - 446px);
      height: calc(100% - 10px);
      overflow-x: auto;
      overflow-y: hidden;

      .content__field {
        width: 80%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;

        embed {
          margin: 3px 0;
          width: 150px;
          height: 30px;
          background: url("../../assets/images/embeddedElement.svg") no-repeat, $embed-back;
          background-position: center;
          border: 1px solid $embed-border;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
          border-radius: 6px;
        }

        :global(.ql-toolbar.ql-snow) {
          border-bottom: 1px solid $gray-border;
        }

        :global(.ql-container.ql-snow) {
          overflow: hidden;
        }

        &.richText :global(.ql-editor) {
          & > h1, h2, h3, h4, h5, h6 {
            font-weight: 500;
            line-height: 1.3;
            margin-bottom: 0.75rem;
          }

          & > h1 {
            font-size: 1.875rem;
          }

          & > h2 {
            font-size: 1.5625rem;
          }

          & > h3 {
            font-size: 1.375rem;
          }

          & > h4 {
            font-size: 1.25rem;
          }

          & > h5 {
            font-size: 1.125rem;
          }

          & > h6 {
            font-size: 1rem;
          }

          & > p {
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 1.5rem;
          }

          code {
            font-family: monospace;
            font-size: 0.9rem;
            background: none;
            padding: 0;

            & * {
              font-family: monospace;
            }
          }

          a, a * {
            font-weight: 500;
            color: #0059C8;
            text-decoration: none;
            font-family: Poppins, sans-serif !important;
          }

          strong, strong * {
            font-weight: 600;
          }

          ul, ol {
            padding: 0;
            margin: 0 0 1.25rem 1.25rem;
          }

          li {
            margin-top: 0.5rem;
            padding-left: 0;
            font-size: initial;
            line-height: initial;
          }

          li[data-list=bullet] > :global(.ql-ui):before {
            vertical-align: middle;
            font-size: 24px;
            line-height: 16px;
          }
        }

        & > div {
          border: 0;
        }
      }

      .content__control {
        @include flex(flex-end, center);
        bottom: 0;
        margin-left: -1px;
        padding: 6px 32px 6px 11px;
        width: calc(100% - 43px);
        background-color: $white;
        z-index: 2;
      }

      .lightningWarnIcon svg path {
        stroke: var(--writer-orange);
        fill: transparent;
      }

      .containerToolButtonContainer {
        position: absolute;
        top: 0;
        right: 20px;
      }

      .containerToolButton {
        font-weight: var(--font-default-weight);
        font-size: var(--font-size-s) !important;
      }

      .toolButtonSquaredLoading i {
        z-index: 2;
      }

      .toolButtonSquaredLoading span {
        z-index: 2;
      }

      .toolButtonSquaredLoadingMauve {
        overflow: hidden;
        background: #c586ff;
      }

      .toolButtonSquaredLoadingMauve:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 200px;
        background: radial-gradient(50% 50% at 50% 50%, #fb76e5 0%, rgba(251, 118, 229, 0) 100%);
        animation: floating-gradient-centered 2s infinite;
      }

      @keyframes floating-gradient-centered {
        0% {
          top: -100%;
          left: -80%;
        }

        100% {
          top: -50%;
          left: 80%;
        }
      }
    }

    .main__team {
      position: absolute;
      text-align: left;
      top: 8px;
      right: 0;
      z-index: 2;
      width: 434px;
      font-size: 0.65rem;
      letter-spacing: 1.3px;
      color: $gray-fonts;
    }
  }
}

:global(.MuiTooltip-tooltip) {
  &.tooltipSmall {
    width: 100px;

    & p {
      font-size: 12px;
    }
  }

  &.tooltipMedium {
    width: 135px;

    & p {
      font-size: 12px;
    }
  }

  &.tooltipLarge {
    width: 175px;

    & p {
      font-size: 12px;
    }
  }

  .autoWriteTooltip {
    margin: 3px 0;
  }

  .contactLink a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }

  .betaTag {
    width: 41px;
    margin: 0 auto;
  }
}
